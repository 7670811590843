import { useState } from 'react'
import axios from 'axios'
import {
	Badge,
	Button,
	Col,
	Container,
	Form,
	InputGroup,
	Nav,
	Row,
	Stack,
} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

function App() {
	const [data, setData] = useState([])
	const [prompt, setPrompt] = useState('')
	const [systemPrompt, setSystemPrompt] = useState('')

	const handleSend = () => {
		axios
			.post(API_BASE_URL + '/api/send_prompt', {
				prompt,
				system_prompt: systemPrompt,
			})
			.then((res) => {
				setPrompt('')
				setData(data.concat(res.data.data))
			})
			.catch((e) => console.error(e))
	}

	return (
		<Container className="h-100">
			<Nav variant="pills" activeKey="1">
				<Nav.Item>
					<Nav.Link href="#/home">
						<h2>Encramble</h2>
					</Nav.Link>
				</Nav.Item>
			</Nav>
			<section className="d-flex flex-column" style={{ marginBottom: '9rem' }}>
				{data.map((d, i) => (
					<Stack className="mb-3" key={i}>
						<Row className="align-self-end">
							<Col className="text-end">
								<Badge
									bg="secondary"
									className="w-75 p-3 fs-6 fw-semibold text-start text-wrap"
								>
									{d.prompt}
								</Badge>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col>
								<div className="text-black-50 form-text">Prompt Scrambled:</div>
								<Badge
									bg="info"
									className="w-75 p-3 fs-6 fw-semibold text-start text-wrap"
								>
									{d.promptScrambled}
								</Badge>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="text-black-50 form-text">Model Response:</div>
								<Badge
									bg="info"
									className="w-75 p-3 fs-6 fw-semibold text-start text-wrap"
								>
									{d.modelRes}
								</Badge>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="text-black-50 form-text">
									Model Response Unscrambled:
								</div>
								<Badge
									bg="info"
									className="w-75 p-3 fs-6 fw-semibold text-start text-wrap"
								>
									{d.modelResUnscrambled}
								</Badge>
							</Col>
						</Row>
					</Stack>
				))}
			</section>

			<section className="row w-75 pb-3 position-fixed bottom-0 bg-white">
				<Col className="py-3 px-4 border-top">
					<InputGroup size="sm" className="mb-2">
						<InputGroup.Text>System Prompt: </InputGroup.Text>
						<Form.Control
							as="textarea"
							rows={2}
							value={systemPrompt}
							onChange={(e) => setSystemPrompt(e.target.value)}
						/>
					</InputGroup>
					<InputGroup>
						<Form.Control
							type="text"
							placeholder="Type your message"
							value={prompt}
							onChange={(e) => setPrompt(e.target.value)}
						/>

						<Button onClick={handleSend} disabled={!prompt}>
							Send
						</Button>
					</InputGroup>
				</Col>
			</section>
		</Container>
	)
}

export default App
